exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/AboutUs.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-bachelor-tab-js": () => import("./../../../src/pages/BachelorTab.js" /* webpackChunkName: "component---src-pages-bachelor-tab-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-email-greetings-js": () => import("./../../../src/pages/EmailGreetings.js" /* webpackChunkName: "component---src-pages-email-greetings-js" */),
  "component---src-pages-engineering-tab-js": () => import("./../../../src/pages/EngineeringTab.js" /* webpackChunkName: "component---src-pages-engineering-tab-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-layout-js": () => import("./../../../src/pages/layout.js" /* webpackChunkName: "component---src-pages-layout-js" */),
  "component---src-pages-master-tab-js": () => import("./../../../src/pages/MasterTab.js" /* webpackChunkName: "component---src-pages-master-tab-js" */),
  "component---src-pages-science-agro-js": () => import("./../../../src/pages/Science/Agro.js" /* webpackChunkName: "component---src-pages-science-agro-js" */),
  "component---src-pages-science-eco-js": () => import("./../../../src/pages/Science/Eco.js" /* webpackChunkName: "component---src-pages-science-eco-js" */),
  "component---src-pages-science-graphic-js": () => import("./../../../src/pages/Science/Graphic.js" /* webpackChunkName: "component---src-pages-science-graphic-js" */),
  "component---src-pages-science-humanity-js": () => import("./../../../src/pages/Science/Humanity.js" /* webpackChunkName: "component---src-pages-science-humanity-js" */),
  "component---src-pages-science-it-js": () => import("./../../../src/pages/Science/IT.js" /* webpackChunkName: "component---src-pages-science-it-js" */),
  "component---src-pages-science-life-js": () => import("./../../../src/pages/Science/Life.js" /* webpackChunkName: "component---src-pages-science-life-js" */),
  "component---src-pages-science-math-js": () => import("./../../../src/pages/Science/Math.js" /* webpackChunkName: "component---src-pages-science-math-js" */),
  "component---src-pages-science-medic-js": () => import("./../../../src/pages/Science/Medic.js" /* webpackChunkName: "component---src-pages-science-medic-js" */),
  "component---src-pages-science-people-js": () => import("./../../../src/pages/Science/People.js" /* webpackChunkName: "component---src-pages-science-people-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/Terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

